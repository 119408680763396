import { Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import React from 'react';
import styles from './styles.module.scss'
ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export const SalesChart = () => {
    const getData = () => {
        const labels = ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb'];

        const datasets = [
            {
                label: 'Sales',
                data: [150000, 250000, 300000, 350000, 270000, 290000, 310000, 260000, 290000, 250000], // Sales data points
                borderColor: '#3AA9F4',  // Light blue for Sales
                backgroundColor: (context: any) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                    gradient.addColorStop(0, '#8CD2F9');  // Stronger gradient at the top
                    gradient.addColorStop(1, 'rgba(140, 210, 249, 0)');  // Fades toward the bottom
                    return gradient;
                },
                fill: true,
                borderWidth: 0,
                pointRadius: 0,
                tension: 0,
            },
            {
                label: 'Profit',
                data: [100000, 150000, 200000, 150000, 180000, 160000, 190000, 170000, 140000, 160000], // Profit data points
                borderColor: '#0019FF',  // Darker blue for Profit
                backgroundColor: (context: any) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                    gradient.addColorStop(1, 'rgba(101, 116, 255, 0)');  // Stronger gradient at the top
                    gradient.addColorStop(0, '#0019FF');  // Fades toward the bottom
                    return gradient;
                },
                fill: true,
                borderWidth: 0,
                pointRadius: 0,
                tension: 0,
            }
        ];

        return {
            labels: labels,
            datasets: datasets,
        };
    };

    const toptions: any = {
        responsive: true,
        elements: {
            point: {
                radius: 0,
            },
        },
        interaction: {
            mode: 'nearest',
        },
        hover: {
            mode: 'nearest',
            intersect: true,
        },
        plugins: {
            legend: {
                display: false,  // Disable default legend
            },
            datalabels: {
                display: false, // Hide data labels on the bars
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        scales: {
            y: {
                min: 0,
                position: 'left',
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    callback: function (value: number) {
                        return `$${(value / 1000).toFixed(0)}k`; // Format ticks as dollars in thousands
                    },
                },
                border: {
                    display: false,
                },
            },
            x: {
                stacked: true,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                },
                border: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div style={{ height: '90%', width: '100%' }}>
            <div className={styles.container}>
                <div className={styles.item}>
                    <div className={styles.sales}>
                        Sales
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.profit}>
                        Profit
                    </div>
                </div>
            </div>
            <Chart
                type="line"
                data={getData()}
                options={toptions}
            />
        </div>

    );
};
