import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormInput from '../../../components/atoms/FormInputField';
import styles from './login.module.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BottomLogo } from '../../../assets/svg/centerLogo.svg';
import { addToast } from '../../../store/toastSlice';
import { useDispatch } from 'react-redux';
import Loader from '../../../components/atoms/loader';
import { CustomButton } from '../../../components/atoms/button/button';
import { apiRequest } from '../../../services/onboard';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { handleNavigate } from '../../../utils';

const defaultTheme = createTheme();

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
});

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false)

  // React.useEffect(() => {
  //   handleNavigate(navigate, "/login")
  // }, [])
  const handleSubmit = async (
    values: { email: string; password: string },
    { setSubmitting, setErrors }: { setSubmitting: (isSubmitting: boolean) => void; setErrors: (errors: any) => void }
  ) => {
    setSubmitting(true);

    try {
      const formData = new FormData();
      formData.append('username', values.email);
      formData.append('password', values.password);
      formData.append('grant_type', '');
      const response = await apiRequest({
        method: 'POST',
        url: `${process.env.REACT_APP_BASE_URL}/auth/signin`,
        data: formData,
        config: {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        dispatch,
        errorMessage: 'Signin failed. Please try again.',
      });

      // Handle the response
      if (response?.data) {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('is_onboarded', response.data.is_onboarded);

        // Check if the user is onboarded and navigate accordingly
        if (response.data.is_onboarded) {
          navigate('/home');
        } else {
          navigate(`/onboard?conn=${false}`);
        }
      }
    } catch (error) {
      console.error('An error occurred during the signin process:', error);
      setErrors({ apiError: 'Failed to login. Please try again.' });
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        component='main'
        sx={{ height: '100vh' }}
        className={`font-poppins ${styles.mainCont}`}
      >
        <CssBaseline />
        <Grid
          item
          md={12}
          sx={{
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#5B47F5',
            backgroundSize: 'cover',
            backgroundPosition: 'center',

          }}
          className='max-sm:h-full max-sm:w-full  max-sm:bg-white'
        >
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              width: '100%'
            }}
            className='max-sm:w-full max-sm:h-full'
          >
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className='max-sm:h-full max-sm:w-full'
            >
              {({ isSubmitting, errors, values }: any) => (
                <Form className='max-sm:w-full'>
                  <Box
                    component='div'
                    // noValidate
                    display={'flex'}
                    gap={'24px'}
                    flexDirection={'column'}
                    className={`${styles.mainLoginCont} max-sm:w-full max-sm:p-8 max-sm:justify-center`}
                  >
                    <p className={styles.mainLoginText}>Login to your account</p>
                    <div style={{ width: '100%' }}>
                      <Field
                        as={FormInput}
                        variant='filled'
                        size='small'
                        label='Email'
                        name='email'
                        sx={{}}
                      />
                      <ErrorMessage name='email' component='div' className={styles.errorText} />

                    </div>
                    <Grid sx={{ width: '100%' }}>
                      <Field
                        as={FormInput}
                        variant='filled'
                        size='small'
                        label='Password'
                        name='password'
                        type={show ? "text" : "password"}
                        endIcon={show ? <VisibilityOff onClick={() => setShow(!show)} style={{ cursor: 'pointer' }} /> : <Visibility onClick={() => setShow(!show)} style={{ cursor: 'pointer' }} />}
                        sx={{}}
                      />
                      {/* {(errors.password || values.password) && <PasswordValidation password={values.password} styles={{ margin: 'none' }}/>} */}
                      <ErrorMessage name='password' component='div' className={styles.errorText} />
                      <Grid item display={"flex"} justifyContent={'start'} mt={1}>
                        <Typography
                          color={'rgb(95, 70, 254)'}
                          className='fontStyles'
                          sx={{ marginLeft: '0px', fontSize: '12px', cursor: 'pointer' }}
                          onClick={() => navigate('/auth/forgot-password')}
                        >
                          Forgot Password?
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <ErrorMessage name='password' component='div' className={styles.errorText} /> */}
                    {/* {errors.apiError && <div className={styles.errorText}>{errors.apiError}</div>} */}

                    <Grid sx={{ width: '100%' }}>
                      <CustomButton
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <Loader size={25} /> : "Login"}
                      </CustomButton>
                    </Grid>
                    <Grid container display={'flex'} justifyContent={'start'}>
                      <Grid item>
                        <Typography fontWeight={500} className='fontStyles'>
                          New to Xylic?
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          color={'rgb(95, 70, 254)'}
                          className='fontStyles'
                          sx={{ marginLeft: '4px', cursor: 'pointer' }}
                          onClick={() => navigate('/register')}
                        >
                          Join now
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              )}
            </Formik>
          </Grid>
          {/* <Grid sx={{
            position: 'fixed',
            bottom: 0,
            left: '40%',
            zIndex: 0
          }}>
            <BottomLogo />
          </Grid> */}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
