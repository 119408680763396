import {Typography } from "@mui/material";
import { ReactComponent as DownIcon } from "../../../../../assets/svg/downicon.svg";
import { ReactComponent as UpIcon } from "../../../../../assets/svg/uparr.svg";
import styles from './styles.module.scss';
import { SalesChart } from "./linechart";
import { addComma } from "../../../../../utils/index"


const getTrendIcon = (value: number) => {
    if (value < 0) {
        return <DownIcon />
    } else if (value === 0) {
        return null;
    } else {
        return <UpIcon />
    }
};

const getTextColor = (value: number) => {
    if (value <= 0) {
        return '#F60000';
    } else {
        return '#03A01C';
    }
};



export const KpiSection = () => {
    const kpiData = [
        {
            title: 'Number of Buyers',
            content: addComma(200544),
            footer: (
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    {getTrendIcon(10)}
                    <Typography style={{ color: getTextColor(10), fontWeight: 600, fontSize: '12px' }}>
                        10% <span style={{ color: '#6B7280' }}>vs prev = 185,211</span>
                    </Typography>
                </div>
            ),
            chartData: [150000, 250000, 300000, 350000, 270000, 290000, 310000, 260000, 290000, 250000]
        },
        {
            title: 'Orders',
            content: addComma(200544),
            footer: (
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                    {getTrendIcon(10)}
                    <Typography style={{ color: getTextColor(10), fontWeight: 600, fontSize: '12px' }}>
                        10% <span style={{ color: '#6B7280' }}>vs prev = 185,211</span>
                    </Typography>
                </div>
            ),
            chartData: [100000, 150000, 200000, 150000, 180000, 160000, 190000, 170000, 140000, 160000]
        },
        {
            title: 'Repeat Order Units',
            content: `$ ${addComma(72593)}`,
            footer: <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                {getTrendIcon(0)}
                <Typography style={{ color: getTextColor(10), fontWeight: 600, fontSize: '12px' }}>
                        10% <span style={{ color: '#6B7280' }}>vs prev = 185,211</span>
                    </Typography>
            </div>,
            chartData: []
        },
        {
            title: 'Repeat Customer Share',
            content: addComma(152),
            footer: <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                {getTrendIcon(0)}
                <Typography style={{ color: getTextColor(10), fontWeight: 600, fontSize: '12px' }}>
                        10% <span style={{ color: '#6B7280' }}>vs prev = 185,211</span>
                    </Typography>            </div>,
            chartData: []
        }
    ];

    return (
        <div className={styles.newSmallCards}>
            {kpiData.map((card, index) => (
                <div className={styles.newSmallCard} key={index}>
                    <div className={styles.cardContent}>
                        <Typography className={styles.cardTitle}>{card?.title}</Typography>
                        <Typography className={styles.cardText}>{card?.content}</Typography>
                        <div className={styles.cardFooter}>{card.footer}</div>
                        <SalesChart label={card.title} data={card.chartData} />
                    </div>
                </div>
            ))}
        </div>
    );
};