// NewDashboardLayout.tsx
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import SidebarNew from "../components/organisms/new-sidebar";
import styles from "./styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../pages/new-dashboard/components/header";
import { RootState } from "../store";
import { setShowSidebar, setTab } from '../store/header/reducer';
import { useMediaQuery } from "@mui/system";

const NewDashboardLayout: React.FC = () => {
  const { showSide } = useSelector((state: RootState) => state.headerContext);
  const dispatch = useDispatch()
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if(isSmallScreen){
      dispatch(setShowSidebar(true));
    }
  }, [])
  return (
    <div className="flex flex-col" style={{ minHeight: "100dvh", backgroundColor: "#F7F7F7" }}>
      <div>
        <div style={{  width: "100%", zIndex: 1000, paddingTop: "12px" }}>
          <Header />
        </div>
      </div>
      <div className="flex my-[10px]"  style={{height:'calc(100dvh - 68px)'}}>
      {!showSide && (
          <SidebarNew  />
      )}
      <div
        className="pr-[12px] w-full ml-[10px]">
        <Outlet />
      </div>
      </div>
    </div>
  );
};

export default NewDashboardLayout;
